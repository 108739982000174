import React, { Fragment, useEffect } from "react";
import BasePortableText from "@sanity/block-content-to-react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { graphql } from "gatsby";
import { parseISO, format } from "date-fns";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import SEO from "../components/SEO";

const SingleBlogPage = ({ data: { blog } }) => {
  useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const authorImg = getImage(blog.author.avatar.asset);
  const blogImg = getImage(blog.image.asset);
  const hasDocs = blog.documents.length > 0;
  let docBlock = "";

  if (hasDocs) {
    docBlock = (
      <div className="doc-container p-4 my-4">
        <p className="font-weight-bold" style={{fontSize:"16px", display:"inline"}}>RELATED DOCUMENTS: </p>
        {blog.documents.map((doc) => {
        return (
          <div className="document">
            <a className="font-weight-bold"  target="_blank" rel="noopener noreferrer" style={{fontSize:"16px"}}href={doc.asset.url}>{doc.name}</a>
            <p className="comma">, </p>
          </div>
        );
      })}
      </div>
    );
  }

  return (
    <Fragment>
      <SEO 
        title={blog.title}
        description={blog.metaDescription}
        image={getSrc(blogImg)}
      />
      
      <div className="wrapper content-margin">
        <div className="main">
          <Container>
            <Row>
              <Col>
                <Card className="p-5 w-100">
                  <CardHeader className="d-flex flex-row">
                    <CardTitle>
                      <h1 className="font-weight-bold mb-0">{blog.title}</h1>
                    </CardTitle>
                  </CardHeader>

                  <CardBody className="p-0">
                    <GatsbyImage
                        className="rounded-circle img-fluid img-raised mr-3"
                        image={authorImg}
                        alt="Author"
                        style={{ height: "60px", width: "60px", display:"inline-block", }}
                      />
                    <div style={{display:"inline-block"}}>
                      <h5 className="mb-0">By <b>{blog.author.name}</b></h5>
                      <div
                        className="font-weight-light"
                        style={{ fontSize: "16px" }}
                      >
                        Posted {format(parseISO(blog.date), "PP")}
                      </div>
                    </div>
                    <div className="py-4 img-container">
                      <GatsbyImage image={blogImg} alt={blog.title} />
                    </div>
                    <h6>{blog.subtitle}</h6>
                    {docBlock}
                    <BasePortableText className="pt-2" blocks={blog._rawBody} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export const query = graphql`
  query($slug: String!) {
    blog: sanityBlog(slug: { current: { eq: $slug } }) {
      title
      subtitle
      metaDescription
      _rawBody
      author {
        name
        avatar {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: BLURRED)
          }
        }
      }
      date
      image {
        asset {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      documents {
        name
        asset {
          url
        }
      }
    }
  }
`;

export default SingleBlogPage;
